import MainComponent from './components/MainComponent';
import "./App.css"
function App() {
  return (
    <div className='grad'>
      <MainComponent />
    </div>
  );
}

export default App;
